html,
body,
#root {
  height: 100%;
  width: 100%;
}

body * {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}
